import React from 'react';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import CommonHeader from '../Components/Headers/CommonHeader';
import { LegalContent } from '../Components/Legal/LegalContent';
import { LegalNav } from '../Components/Legal/LegalNav';
import Footer from '../Components/Home/Footer';

const LegalNotices = () => {
  ReactGA.send({ hitType: 'pageview', page: '/Legal', title: 'Legal Notices' });

  return (
    <React.Fragment>
      <Helmet>
        <title>DISGENET: Legal Notices</title>
        <meta
          name="description"
          content="Explore DISGENET's legal notices, stay informed about your rights and responsibilities when using our platform."
        />

        {/* Open Graph Tags for LinkedIn */}
        <meta property="og:title" content="DISGENET: Legal Notices" />
        <meta
          property="og:description"
          content="Learn about the legal notices, terms, and conditions for using DISGENET, ensuring that you're informed about your rights and responsibilities."
        />
        <meta property="og:url" content="https://www.disgenet.com/Legal" />
        <meta property="og:type" content="website" />
      </Helmet>
      <CommonHeader />
      <LegalNav />
      <LegalContent />
      <Footer />
    </React.Fragment>
  );
};

export default LegalNotices;
