import React from 'react';
import { Helmet } from 'react-helmet-async';
import CommonHeader from '../Components/Headers/CommonHeader.jsx';
import AboutContent from '../Components/About/AboutContent.jsx';
import Footer from '../Components/Home/Footer.jsx';
import $ from 'jquery';

export default function About() {
  $('.popover').hide();

  return (
    <React.Fragment>
      <Helmet>
        <title>DISGENET: Database Information</title>
        <meta name="description" content="Information about database statistics, attributes and metrics." />

        <meta property="og:title" content="DISGENET: Database Information" />
        <meta
          property="og:description"
          content="Information about database statistics, attributes and metrics."
        />
        <meta property="og:image" content="url_to_image" />
        <meta property="og:url" content="https://www.disgenet.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="DISGENET: Database Information" />
        <meta
          name="twitter:description"
          content="Information about database statistics, attributes and metrics."
        />
        <meta name="twitter:image" content="url_to_image" />
        <link rel="canonical" href="/About" />

        {/* Tags for LinkedIn */}
        <meta property="og:title" content="DISGENET: Database Information" />
        <meta
          property="og:description"
          content="Explore detailed information about database statistics, attributes, and metrics on DISGENET."
        />
        <meta property="og:url" content="https://www.disgenet.com/About" />
        <meta property="og:image" content="https://www.disgenet.com/About" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="/About" />
      </Helmet>
      <CommonHeader />
      <AboutContent />
      <Footer />
    </React.Fragment>
  );
}
