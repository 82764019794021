import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAppContext } from '../../Context/Context';
import { USER_ROLES_AWS_MAP } from '../Constants';
import QueriesLimitTable from '../API/QueriesLimitTable';
import UpgradePlan from '../User/UpgradePlan';

const ProfileData = ({ profileDataClass }) => {
  const { user, groups } = userAppContext();
  const [message] = useState('');

  const navigate = useNavigate();

  const handleReset = evt => {
    evt.preventDefault();
    navigate('/Reset');
  };

  const userRole = USER_ROLES_AWS_MAP.get(groups[0]);
  const isExternal = user.identities ? true : false; // if user is from an external identity provider, then it has identities field

  return (
    <>
      <div className={`${profileDataClass}`} id="profilelData">
        <h2>Profile Data</h2>
        <div className="wrap-input100" />

        {user['custom:company'] ? (
          <>
            {' '}
            <p className="input100">Company: </p>
            <span className="label-input100">{user['custom:company']}</span>
            <div className="wrap-input100" />
          </>
        ) : null}

        <p className="input100">Email: </p>
        <span className="label-input100">{user['email']}</span>
        <div className="wrap-input100" />

        <p className="input100">Plan: </p>
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="label-input100">{userRole}</h6>
          <UpgradePlan />
        </div>
        <div className="wrap-input100" />
        <div className="pt-4">
          <QueriesLimitTable />
        </div>
        <div className="" />
        <p style={{ color: 'red' }}>{message}</p>
        {!isExternal && (
          <a className="input100 resetApiKeyandPass" href="#" onClick={evt => handleReset(evt)}>
            Change Password
          </a>
        )}
      </div>
      {/* 
      {showUpgradeModal && (
        <UpgradePlan showUpgradeModal={showUpgradeModal} setShowUpgradeModal={setShowUpgradeModal} />
      )} */}
    </>
  );
};

export default ProfileData;
