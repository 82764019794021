import React, { useState, useEffect } from 'react';
import { userAppContext } from '../../Context/Context';

const UpgradePlan = () => {
  const { user, groups = [] } = userAppContext();
  const isTrial = groups.includes('ROLE_TRIAL');
  const isAcademic = groups.includes('ROLE_ACADEMIC');

  const [showModal, setShowModal] = useState(false);
  const [showModalAcademic, setShowModalAcademic] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    selectedPlan: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user['custom:name'] || '',
        email: user.email || '',
        selectedPlan: '',
      });
    }
  }, [user]);

  const handleUpgradeClick = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false) || setShowModalAcademic(false);

  const handleApplyToAcademicClick = () => setShowModalAcademic(true);

  const handleSubmit = e => {
    e.preventDefault();
    alert(`Selected Plan: ${formData.selectedPlan}`);
    setShowModal(false);
  };

  const handleAcademicSubmit = () => {
    alert('Application submitted for review.');
    setShowModalAcademic(false);
  };

  return (
    <div>
      {isTrial && !isAcademic && (
        <div>
          <button className="btn-gradient-blue" onClick={handleApplyToAcademicClick}>
            Apply to Academic Plan
          </button>
          <button className="btn-gradient-blue" onClick={handleUpgradeClick}>
            Upgrade your Plan
          </button>
        </div>
      )}
      {isAcademic && !isTrial && (
        <div>
          <button className="btn-gradient-blue" onClick={handleUpgradeClick}>
            Upgrade your Plan
          </button>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="popup-upgrade">
            <button className="modal-close-btn" onClick={handleCloseModal}>
              x
            </button>

            <h3 className="text-center">Upgrade Your Plan</h3>

            <form onSubmit={handleSubmit}>
              <div>
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} readOnly />
              </div>
              <div>
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} readOnly />
              </div>
              <div>
                <label>Select Plan:</label>
                <select
                  name="selectedPlan"
                  value={formData.selectedPlan}
                  onChange={e => setFormData(prev => ({ ...prev, selectedPlan: e.target.value }))}
                >
                  <option value="">...</option>
                  <option value="Standard">Standard</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button type="submit" className="btn-gradient-blue">
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Academic Modal */}
      {showModalAcademic && (
        <div className="modal-overlay">
          <div className="RegisterPopup">
            <div className="apply-popup-container">
              <button className="modal-close-btn" onClick={handleCloseModal}>
                x
              </button>
              <p>Do you want to confirm your application for academic?</p>

              <button onClick={handleAcademicSubmit} className="btn-gradient-blue" aria-label="submit-modal">
                Confirm & Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpgradePlan;
