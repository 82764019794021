import React, { useState, useEffect, useRef } from 'react';
import { signOut } from 'aws-amplify/auth';
import { userAppContext } from '../../Context/Context';
import { Link, useLocation } from 'react-router-dom';

const TopRightMenu = ({ renderizedBy }) => {
  const { isAuthenticated, setIsAuthenticated, setGroups, setAuthorization } = userAppContext();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const topRightMenuRef = useRef(null);
  const location = useLocation();

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  useEffect(() => {
    const calculateDropdownPosition = () => {
      if (topRightMenuRef.current && dropdownRef.current) {
        const topRightMenuRect = topRightMenuRef.current.getBoundingClientRect();
        const dropdownRect = dropdownRef.current.getBoundingClientRect();

        const top = topRightMenuRect.bottom + window.scrollY;
        const left = topRightMenuRect.left - (dropdownRect.width - topRightMenuRect.width);

        setDropdownPosition({ top, left });
      }
    };

    calculateDropdownPosition();

    window.addEventListener('resize', calculateDropdownPosition);

    return () => {
      window.removeEventListener('resize', calculateDropdownPosition);
    };
  }, [isDropdownOpen]);

  const handleLogout = () => {
    setDropdownOpen(false);
    setIsAuthenticated(false);
    setGroups([]);
    setAuthorization('');
    signOut();
  };

  const handleLabelClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleClickOutside = event => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      topRightMenuRef.current &&
      !topRightMenuRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return isAuthenticated ? (
    <>
      <div className="menuNav" ref={topRightMenuRef}>
        <Link to="/Profile-area">
          <i
            className={`bi bi-person-circle nav-icon ${renderizedBy === 'ApplyPage' ? 'custom-nav-menu' : ''}`}
          />
        </Link>
        <span onClick={handleLabelClick}>
          <i className={`bi bi-list nav-icon ${renderizedBy === 'ApplyPage' ? 'custom-nav-menu' : ''}`}></i>
        </span>
      </div>
      <nav
        className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}
        style={location.pathname !== '/' ? { top: dropdownPosition.top } : {}}
        ref={dropdownRef}
      >
        <ul className="slide">
          <li>
            <Link to="/About">ABOUT</Link>
          </li>
          <li>
            <Link to="/Tools">TOOLS</Link>
          </li>
          <li>
            <Link to="/blog">BLOG</Link>
          </li>
          <li>
            <Link to="/Assistant">
              AI Assistant <i className="bi bi-stars"></i>
            </Link>
          </li>
          <li>
            <Link to="/Support">SUPPORT</Link>
          </li>
          <li>
            <Link to="/" onClick={handleLogout}>
              LOGOUT
            </Link>
          </li>
          {/* <li>
            <a href="https://www.linkedin.com/company/medbioinformatics/" target="_blank" rel="noreferrer">
              {' '}
              <img src="../static/images/linkedin-blue.svg" style={{ width: '20px', height: '20px' }} />
            </a>
          </li> */}
        </ul>
      </nav>
    </>
  ) : (
    <>
      <div className="menuNav" ref={topRightMenuRef}>
        <Link className="custom-link" to="/login">
          <i className="bi bi-person"></i> LOGIN
        </Link>
        <Link className="custom-link" to="/plans">
          <i className="bi bi-person-plus"></i> REGISTER
        </Link>
        <span onClick={handleLabelClick}>
          <i className={`bi bi-list nav-icon ${renderizedBy === 'ApplyPage' ? 'custom-nav-menu' : ''}`}></i>
        </span>
      </div>
      <div>
        <nav
          className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}
          style={location.pathname !== '/' ? { top: dropdownPosition.top } : {}}
          ref={dropdownRef}
        >
          <ul className="slide">
            <li>
              <Link to="/About">ABOUT</Link>
            </li>
            <li>
              <Link to="/plans">PLANS</Link>
            </li>
            <li>
              <Link to="/academic-apply">ACADEMIC APPLY</Link>
            </li>
            <li>
              <Link to="/blog">BLOG</Link>
            </li>
            <li>
              <Link to="/Support">SUPPORT</Link>
            </li>
            {/* <li>
              <a href="https://www.linkedin.com/company/medbioinformatics/" target="_blank" rel="noreferrer">
                {' '}
                <img src="../static/images/linkedin-blue.svg" style={{ width: '12px', height: '12px' }} />
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </>
  );
};

export { TopRightMenu };
