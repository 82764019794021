import React, { useState, useEffect } from 'react';
import { signIn, signOut } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';

import { userAppContext } from '../../Context/Context';
import Reset from '../User/Reset';
import { refreshSession } from '../../Services/RefreshSession';

function validateEmail(email) {
  return /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9\-]+\.[a-zA-Z]{1,5}$/.test(email);
}

function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { isAuthenticated, setIsAuthenticated, setAuthorization, setGroups, setUser } = userAppContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const togglePassVisibility = () => {
    setIsPassVisible(prevState => !prevState);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsAnimating(true);
      setTimeout(() => {
        navigate('/');
        setIsAnimating(false);
      }, 100);
    }
  }, [isAuthenticated, navigate]);

  const handleChangeEmail = event => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleChangePassword = event => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 8);
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    try {
      setIsLoading(true); // Set loading to true when the button is clicked
      const user = await signIn({ username: email, password, authFlowType: 'USER_SRP_AUTH' });
      if (user.isSignedIn) {
        const session = await refreshSession();
        // refresh app state
        const userInfo = session.tokens.idToken.payload;
        const userGroups = userInfo['cognito:groups'];
        setUser(userInfo);
        setAuthorization(session.tokens.idToken.toString());
        setGroups(userGroups);
        setIsAuthenticated(true);
      } else if (user.nextStep?.signInStep === 'CONFIRM_SIGN_UP') {
        throw new Error('Please check your email for a confirmation link');
      } else {
        throw new Error('An error occurred. Please contact it@disgenet.com');
      }
    } catch (ex) {
      setErrorMessage(ex.message);
      // cleanup session
      setIsAuthenticated(false);
      setGroups([]);
      setAuthorization('');
      await signOut();
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = evt => {
    evt.preventDefault();
    setReset(true);
  };

  return (
    <div>
      <link rel="canonical" href="/login" />
      {reset ? (
        <Reset setReset={setReset} />
      ) : (
        <div className={`container txt-contenido ${isAnimating ? 'fade-out' : ''}`}>
          {isLoading && (
            <div className="loading-overlay">
              <div className="loading" />
            </div>
          )}
          <div className="login-container mb-5">
            <div className="signin-container">
              <div className="col-12">
                <button type="button" className="close" aria-label="Close">
                  <Link style={{ color: 'white' }} to="/" aria-hidden="true">
                    ×
                  </Link>
                </button>
                <h3 className="login-title">SIGN IN</h3>
                <form className="validate-form" onSubmit={handleSubmit}>
                  <div className="wrap-input100 validate-input" data-validate="Email is required">
                    <label className="label-input100" style={{ color: 'white' }}>
                      Email:
                    </label>
                    <input
                      className="input100"
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChangeEmail}
                      required
                    />
                  </div>
                  <div className="wrap-input100 validate-input" data-validate="Password is required">
                    <div className="password-input">
                      <span className="label-input100" style={{ color: 'white' }}>
                        Password
                      </span>
                      <span className="eye-icon" onClick={togglePassVisibility}>
                        <i
                          className={`fa ${isPassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                          style={{ color: 'white' }}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      onChange={handleChangePassword}
                      name="password"
                      id="password"
                      type={`${isPassVisible ? 'text' : 'password'}`}
                      className="input100"
                      placeholder="Password"
                    />
                    <span className="focus-input100 d-flex align-items-center justify-content-end"></span>
                  </div>
                  <div className="flex w-full justify-content-between">
                    <Link to="/sso">
                      <a style={{ float: 'right', marginBottom: '20px', color: 'white' }}>SSO Login</a>{' '}
                    </Link>
                    <a
                      style={{ float: 'right', marginBottom: '20px', color: 'white' }}
                      href="true"
                      onClick={evt => {
                        handleReset(evt);
                      }}
                    >
                      Forgot Password
                    </a>
                  </div>

                  <div className="container-contact100-form-btn">
                    <div className="wrap-contact100-form-btn">
                      <div className="contact100-form-bgbtn" />
                      <button className="contact100-form-btn">Send</button>
                    </div>
                  </div>
                  {errorMessage ? <p className="text-danger text-center">{errorMessage}</p> : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
