function getIdentifiersList(data, identifiers) {
  const identsString = [];

  for (let ident of identifiers) {
    const identsList = new Set(
      data?.map(row => {
        const prefix = ident.includes('UMLSCUI') ? 'UMLS_' : '';
        return `${prefix}${row[ident]}`;
      }),
    );
    identsString.push([...identsList].join(','));
  }

  return identsString;
}

function getSecondUrl(baseUrl, source, keySearch, identParam, identReqParam, pagingInfo, orderByParam) {
  let url2;

  if (Array.isArray(identParam)) {
    const searchParamsArray = identParam.map((param, index) => {
      return `${param}=${identReqParam[index]}`;
    });
    const searchParamsString = searchParamsArray.join('&');

    url2 = `${baseUrl}?source=${source}&${searchParamsString}&page_number=${pagingInfo.currentPage}&page_size=${100}&order_by=${orderByParam}&order=desc`;
  } else {
    url2 = `${baseUrl}?source=${source}&${keySearch}&${identParam}=${identReqParam}&page_number=${pagingInfo.currentPage}&page_size=${100}&order_by=${orderByParam}&order=desc`;
  }

  return url2;
}

function getRemainingPages(pageInfo) {
  if (!pageInfo) return 0;

  const { totalElements, pageSize, currentPageNumber } = pageInfo;

  if (!totalElements || !pageSize || currentPageNumber === undefined) return 0;

  const totalPages = Math.ceil(totalElements / pageSize);
  const remainingPages = totalPages - (currentPageNumber + 1);

  return remainingPages > 0 ? remainingPages : 0;
}

export { getIdentifiersList, getSecondUrl, getRemainingPages };
