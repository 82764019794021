import { fetchAuthSession } from 'aws-amplify/auth';
import { checkTrialExpiration } from './CheckTrialExpiration';
import { USER_ROLES_AWS_MAP } from '../Components/Constants';

export async function refreshSession() {
  const session = await fetchAuthSession();
  if (!session.tokens) {
    return null;
  }
  // refresh app state
  const userInfo = session.tokens.idToken.payload;
  const userId = userInfo['cognito:username'];
  const userGroup = userInfo['cognito:groups'][0];

  if (!(userInfo && userId && userGroup)) {
    throw new Error('Error retrieving user. Please contact it@disgenet.com');
  } else {
    if (userGroup === 'ROLE_TRIAL') {
      // check trial status
      if (checkTrialExpiration(userInfo)) {
        // stop authentication for expired trial users
        throw new Error('Your trial for 7 days has expired.');
      }
    } else if (userGroup && ![...USER_ROLES_AWS_MAP.keys()].includes(userGroup)) {
      // user is not in any registered userGroup
      throw new Error('User is not in any known userGroup. Please contact it@disgenet.com');
    }
  }
  return session;
}
